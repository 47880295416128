@import 'src/commons/styles/color.scss';
@import 'src/commons/styles/variables.scss';

.goog-te-gadget .goog-te-combo {
  margin: 4px 0;
  padding: 5px 17px;
  border-radius: 5px;
}
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}

.cushion-redirect-wrapper {
  position: relative;

  img {
    width: 100%;
    aspect-ratio: auto;
  }

  .cushion-redirect-btn {
    z-index: 1;
    position: absolute;
    left: 9%;
    top: 85%;
    background: red;
    padding: 15px 100px;
    color: #fff;
    border-radius: 3px;
    font-weight: 600;
    transition: all 0.2s ease-in;

    &:hover {
      text-decoration: none;
      background: #cc0099;
      transition: all 0.2s ease-in;
    }
  }

  @media (max-width: 800px) {
    .cushion-redirect-btn {
      top: 82%;
      padding: 10px 100px;
    }
  }

  @media (max-width: 500px) {
    .cushion-redirect-btn {
      top: 80%;
      padding: 5px 50px;
    }
  }
}

.cushion-layout-container {
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
