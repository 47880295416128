@import '/src/commons/styles/color.scss';

.loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: $loading-background-color;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}
