@import '~@coreui/coreui/scss/coreui';
@import 'src/commons/styles/color.scss';
@import 'src/commons/styles/variables.scss';

html {
  background-color: $paper-color;
}
/* Works on Firefox */
// * {
//   scrollbar-width: thin;
//   scrollbar-color: blue orange;
// }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 14px;
  border: 1px solid #c4c4c4;
}
* {
  // font-family: Mukta;
  font-family: Roboto, 'Noto Sans JP', sans-serif !important;
}
.App {
  background-color: $paper-color;
  text-align: center;
  width: 100%;
  display: block;
  flex-direction: row;
  min-height: 100vh;
  color: $text-color;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7093 0.0764334L6.00001 2.10324L1.29071 0.0764334C0.704494 -0.175895 0.0113296 0.22484 0.000149911 0.862972C-0.00277978 1.02593 0.0371343 1.19384 0.128423 1.35186L5.17878 10.0994C5.36134 10.4154 5.68067 10.5735 6.00001 10.5735C6.31934 10.5735 6.63868 10.4154 6.82124 10.0994L11.8716 1.35186C11.9629 1.19384 12.0028 1.02593 11.9999 0.862972C11.9887 0.22484 11.2956 -0.175895 10.7093 0.0764334Z' fill='%234B4B4B'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 10px;
}

textarea {
  resize: none;
}
// mob -------------------------------------------------
.main > .content {
  // overflow: scroll;
  // height: calc(100vh - 65px) !important;
}
.body {
  overflow: hidden;
}
button:focus {
  outline: none;
}
// Mui-css -------------------------------------------------------------------
.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 11px;
  font-weight: 400;
  padding: 8px 12px;
  line-height: 16px;
  background-color: #757575;
}
span.highlight {
  svg {
    path {
      fill: #cc0099;
      fill-opacity: 1;
      opacity: 1;
    }
  }
}

.MuiInputBase-root {
  color: $font-color-black;
}
.WithStyles\(ForwardRef\(InputBase\)\)-input-51 {
  border: 1px solid $border-color;
  color: $font-color-gray;
}

// @media (max-width: 479px) {
//   .main > .content {
//     // overflow: scroll;
//     height: calc(100vh - 112px) !important;
//   }
// }

// @media (min-width: 577px) and (max-width: 767px) {
//   .main > .content {
//     height: calc(100vh - 127px) !important;
//   }
// }

// 全モーダルの設定
// 100vhを100%に変更
.modal-backdrop {
  height: 100%;
}
.modal {
  padding: 5rem 0;
  .modal-dialog {
    margin: auto;
    margin-top: 5rem;
    @media (max-width: $sm) {
      width: calc(100% - 24px);
      margin: auto;
    }
    .modal-body {
      padding: 40px 28px;
      @media (max-width: $sm) {
        padding: 24px 16px;
      }
    }
  }
  .buttons {
    margin-top: 50px;
    position: relative;
  }
}
.buttons {
  margin-top: 50px;
  position: relative;
}
.MuiFormLabel-root.Mui-focused {
  color: $font-color-gray;
}
.Mui-focused * {
  border-color: $prime-color !important;
}
.css-1pahdxg-control:hover {
  border-color: $prime-color !important;
  box-shadow: none;
}
.css-1pahdxg-control {
  border: 2px solid $prime-color !important;
  box-shadow: none !important;
}
.css-26l3qy-menu > div > div:hover {
  background-color: #eee;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: $prime-color !important;
  outline: none;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $prime-color !important;
  outline: none;
}

// カレンダーの矢印の位置補正
.react-datepicker__header {
  line-height: 1.4;
}
.react-datepicker__navigation-icon--previous::before {
  top: 50% !important;
  transform: rotate(225deg) translateY(-50%) !important;
  transform-origin: top;
}
.react-datepicker__navigation-icon--next::before {
  top: 50% !important;
  transform: rotate(45deg) translateY(-50%) !important;
  transform-origin: top;
}
