$paper-color: #fff;
// $background-color: rgba(229, 229, 229, 0.35);
$background-color-contrast: var(--contrast-color);
$background-color-list-odd: var(--contrast-color);
$nav-color: #181818;
$text-color: #4b4b4b;
$text-color-selected: #ff8c05;
$validation-error-color: #ff0000;
$divider-color: #d3d3d3;
$black: #000;
$gray: #9b9b9b;
// $border-color: #828282;
$address-color: #ffa07a;
$active-color: #dc9090;
$blue: #43b9de;
$side-menu-color: var(--side-menu-color);
$side-menu-selected-color: var(--side-menu-selected-color);
$accent-color: var(--side-menu-color);
$reset-color: #828282;
$loading-background-color: rgba(0, 0, 0, 0.5);
$menu-hover-color: #cc0099;
$header-text-color: var(--header-text-color);
$button-active-bg-color: #fbebf7;
$button-active-color: #cc0099;
$switch-active-bg-color: #e580cc;
$switch-bg-color: #9c9299;
$user-border-color: #e0e0e0;
$text-color2: #666666;
// mob
$prime-color: var(--side-menu-color); // #cc0099
$third-color: var(--contrast-color); // #fbebf7
$background-color: var(--background-color); // #f3f4f6
$paper-color: #fff;
$tooltip-color: var(--tooltip-color); // #757575
$button-active-main-color: var(--prime-color); // #cc0099
$border-color: #bbbbbb; // #bbbbbb
$button-disabled-main-color: #ffffff;
$button-disabled-back-color: #919191;
$font-color-black: #000000;
$font-color-darkGray: #333333;
$font-color-gray: #666666;
$font-color-lightGlay: #999999; // place-holder
$font-color-SLightGray: #d9d9d9; // button
$font-color-white: #ffffff;
$attention-color: #d32f2f;
