@import 'src/commons/styles/color.scss';
@import 'src/commons/styles/variables.scss';

.preview-title {
  margin: 20px auto 0px;

  h2 {
    color: $font-color-gray;
    max-width: 300px;
  }
}
.cushion-lang-selection {
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.preview-description {
  display: center;
}

.des-box {
  text-align: left;
  max-width: 300px;
  height: auto;
  white-space: pre-line;
}

.preview-keywords {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 300px;

  .keywords-tags {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .keyword-tag {
      color: black;
      margin: 0 5px;
      padding: 4px 16px;
      border: 1px solid #00ab8c;
      border-radius: 8px;
      font-size: 14px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}

.preview-rating {
  margin: 10px auto;

  p {
    font-size: 30px;
  }

  .star {
    font-size: 28px;
  }
}

.selected-photo {
  width: 100%;
  max-width: 300px;
  max-height: 200px;
  margin: 10px auto;
}

.empty-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 300px;
  border: 2px dashed $border-color;
  margin-bottom: 10px;
}

.empty-image-section input[type='file'] {
  display: none;
}

.empty-image-section:before {
  font-size: 18px;
  color: $font-color-gray;
}

.empty-title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 300px;
  background-color: #f0f0f0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.empty-description-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 300px;
  background-color: #f0f0f0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.empty-keywords-section {
  min-width: 300px;
  background-color: #ebebeb;
  border-radius: 10px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .keywords-text {
    margin-top: 10px;
    display: flex;
    max-width: 200px;
    font-size: 16px;
    font-weight: bold;  
    span:first-child {
      color: #00ab8c;
      margin-right: 5px;

      span:last-child {
        color: #4f4f4f;
      }
    }
  }
}

.empty-content {
  width: 200px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  margin-top: 10px;
}

.empty-content-keywords {
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00ab8c;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 4px 16px;
  border: 1px solid #00ab8c;
  border-radius: 8px;
}

.preview-actions .btn {
  border-color: #00ab8c;
  color: #00ab8c;
  padding: 8px 18px;
  text-align: center;
  font-size: 10px;
  margin: 10px;
  width: 200px;
  border-radius: 50px;
}

.preview-actions .btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.user-ratings {
  min-width: 300px;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  border-radius: 10px;

  .keywords-text {
    margin-top: 10px;
    display: flex;
    font-size: 16px;
    font-weight: bold;  
    span:first-child {
      color: #00ab8c;
      margin-right: 5px;

      span:last-child {
        color: #4f4f4f;
      }
    }
  }
}

.rate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.rate .star {
  margin: 12px;
  margin-top: 2px;
}

.star-review {
  cursor: pointer;
}

.comment-box {
  min-width: 300px;
  background-color: #ebebeb;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 15px;

  .keywords-text {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;  
    span:first-child {
      color: #00ab8c;
      margin-right: 5px;

      span:last-child {
        color: #4f4f4f;
      }
    }
  }
}

.comment-box textarea {
  width: 90%;
  padding: 5px 7px;
  height: 100px;
  border: 1px solid #ccc;
  resize: none;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.comment-box textarea:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.ratings-action {
  margin-top: 20px;
}

.generate-caption .btn {
  background-color: #00ab8c;
  color: #fff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 4px 26px;
  border-radius: 50px;
}

.keywords-select {
  min-width: 200px;
  background-color: #ebebeb;
  border-radius: 10px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .keywords-text {
    margin-top: 10px;
    max-width: 200px;
    display: flex;
    font-size: 16px;
    font-weight: bold;  

    span:first-child {
      color: #00ab8c;
      margin-right: 5px;

      span:last-child {
        color: #4f4f4f;
      }
    }
  }
}

.keyword-tag.selected {
  background-color: #00ab8c;
  color: #f0f0f0;
}

.generate-caption .btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.confirm-section {
  position: relative;
  // width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .confirm-btn {
    position: absolute;
    bottom: 66px;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 12%;
    background: #1f9ca0;
    color: white;
    font-size: 17px;
    font-weight: 700;
    margin-left: auto;
    border-radius: 50px;
    box-shadow: 2px 5px 0px 3px rgba(31, 156, 160, 0.3);
    animation: glow 0.5s infinite alternate;

    @media (max-width: 800px) {
      // bottom: 65px;
    }

    @media (max-width: 650px) {
      font-size: 17px;
      // bottom: 50px;
    }

    @media (max-width: 450px) {
      // bottom: 40px;
    }
  }

  @keyframes glow {
    0% {
      box-shadow: 2px 5px 0px 3px rgba(31, 156, 160, 0.3);
    }
    100% {
      box-shadow: 0 0 5px rgba(31, 156, 160, 0.8),
        0 0 40px rgba(31, 156, 160, 0.6), 0 0 60px rgba(31, 156, 160, 0.4);
    }
  }
}
